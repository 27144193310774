<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Первая сборка головы и тела</h3>
        </div>
                
        <VideoView video-id="02c1bb01805442d299a1be1ab6eaeef5"/>

        <div class="MBtextcont">
            <h3>2. Подготовка рук и ног к сборке</h3>
        </div>
                
        <VideoView video-id="798c377bb3bc47dc88f47a218d94391b"/>

        <div class="MBtextcont">
            <p>Вы можете собрать руки и ноги уже сейчас без проклеенных шарнироприемников, чтобы просто посмотреть, как все двигается. Сборку я показываю в 5-ом последнем видео.</p>
            <h3>3. Проклейка шарнироприемников в руках и ногах</h3>
        </div>
                
        <VideoView video-id="ea94e975e58d49fba591b3c559ca0460"/>

        <div class="MBtextcont">
            <h3>4. Проклейка шарнироприемника в голове</h3>
        </div>
                
        <VideoView video-id="e19154b317454681bc64a6bdd385c649"/>

        <div class="MBtextcont">
            <h3>5. Сборка рук и ног</h3>
        </div>
                
        <VideoView video-id="9754249b53214b168caa08802686ae1b"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Полностью доработать руки, ноги, кисти, стопы, все отшкурить, убрать излишки клея, идеально выгладить</li>
                <li>Проклеить шарнироприемники в руках, ногах, голове</li>
                <li>Собрать руки, ноги, голову, проверить на подвижность</li>
                <li><b>Подготовить материалы</b> к следующему занятию:</li>
                <ol type="a">
                    <li>Шкурка козы или ламы (10см на 15см)</li>
                    <li>Пуходерка</li>
                    <li>Краска для волос (желаемого цвета)</li>
                    <li>Пищевая пленка</li>
                    <li>Малярный скотч</li>
                    <li>Маркер</li>
                </ol>
            </ol>

           <h3>Время выполнения: 2 часа</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>